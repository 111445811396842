@import 'foundation-sites/scss/foundation';
@import '../../../../styles/settings';

.campus-mobilemenu {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 1100;
  background-color: $white;
  @include margin(1.25);

  .nav-item {
    .accordion-title {
      color: $linkColor;

      &:not(.sub-navitem) {
        font-size: 19px;
        text-decoration: none;
        @include padding(1.25, 3, 1.25, 3);
      }

      &::before {
        display: none;
      }

      &.has-content {
        & + .accordion-content {
          padding: 0px;
        }

        .accordion-icon {
          position: absolute;
          left: 16px;
          top: 20px;

          color: $black;
        }
      }

      &.sub-navitem {
        border: 0px !important;
        font-size: 16px;
        line-height: 18px;
        @include padding(0.75, 3, 0.75, 3);
      }
    }
    &.is-active {
      .accordion-title {
        &.has-content {
          .accordion-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

$breakpoints: (
  small: 0,
  mobile: 568px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$strands-palette: (
  orange: #ff5951,
  sky-blue: #5fbbf4,
  purple: #785bee,
  green: #68Cd00,
  pink: #e41D7f,
);

$strands-buttons-palette: (
  analysis: #206036,
  commercial: #205d86,
  communications: #664ab9,
  digital: #c03786,
  finance: #d2326f,
  hr: #b8572e,
  operational: #cb3625,
  policy: #29754f,
  project: #5f1169,
  property: #5871ad,
  security: #002e99,
  science: #756c6c,
  all: #990000,
);

$foundation-palette: (
  primary: #d2377e,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

$body-font-family: Arial, Helvetica, sans-serif;

$white: #ffffff;
$white90: rgba(255, 255, 255, 0.9);
$black: #000000;
$gray: #e5e5e5;

$linkColor: #1c7397;
$highlight-color: #ca3887;
$menu-border-color: #ababab;
$menu-item-background-active: $white;
$menu-item-color-active: $black;
$footer-background: #f3f2f1;
$footer-border-color: #b1b4b6;
$menu-active-color: #6c757d;
$breadcrumbs-background: #f8f9fa;
$strands-title-color: #d2377e;
$course-item-border: #dee2e6;
$course-title-color: #007bff;
$pagination-disabled: #abb5be;
$pagination-actived: #ffb700;

$button-sizes: (
  tiny: 0.6rem,
  small: 0.75rem,
  default: 1rem,
  semi: 1.125rem,
  large: 1.25rem,
);
$button-radius: 4px;
$button-padding: 0.625em 1em;
$button-margin: 0 0 0 0;
$global-button-cursor: pointer;

$topbar-padding: 0.5rem 0px;
$topbar-height: 120px;
$topbar-height-mobile: 60px;
$topbar-background: $white;

$menu-simple-margin: 1.5rem;

$input-radius: 10px;

$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-font-size: 1rem;
$breadcrumbs-item-color: #68717a;

$anchor-color: #0b0c0c;
// $anchor-color-hover
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;



@function get-breakpoint($key) {
  @if map-has-key($breakpoints, $key) {
    @return map-get($breakpoints, $key);
  } @else {
    @error 'given $key is not available in $breakpoints';
  }
}

@mixin breakpointup($key) {
  @media only screen and (min-width: #{get-breakpoint($key)}) {
    @content;
  }
}

@mixin breakpointdown($key) {
  @media only screen and (max-width: #{get-breakpoint($key)}) {
    @content;
  }
}

@mixin breakpointbetween($up, $down) {
  @media only screen and (min-width: #{get-breakpoint($up)}) and (max-width: #{get-breakpoint($down)}) {
    @content;
  }
}

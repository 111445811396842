@use 'sass:math';
@import '../../styles/settings';
@import 'foundation-sites/scss/foundation';

.campus-navigation {
  .campus-menu {
    @include menu-simple;
    font-size: 1.125rem;

    li {
      padding: $menu-items-padding;
      cursor: $global-button-cursor;

      a {
        color: $linkColor;
      }

      &.main-menu-item {
        &.active {
          color: $black;
        }

        & + li {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 1px;
            height: 100%;
            top: 0;
            left: math.div(-$menu-simple-margin, 2);
            background-color: $menu-border-color;
            position: absolute;
          }
        }

        &.is-open {
          .campus-submenu {
            display: block;

            &__icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .campus-menu-trigger {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.4375rem 1rem;
    border-left: 1px solid $menu-border-color;
    cursor: $global-button-cursor;

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &.opened {
    .campus-menu-trigger {
      &__icon {
        transform: rotate(180deg);
      }
    }
  }
}

@import '../../styles/settings';
@import 'foundation-sites/scss/foundation';

.campus-header {
  @include position(relative);
  @include padding(0, 0, 1.25, 0);

  &::after {
    content: '';
    display: block;
    background: url('../../assets/images/top-bar-bottom.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    @include padding(1.25, null, null, null);
    @include position(absolute, null, 0, 0, 0);
  }

  .topbar {
    @include top-bar-container;

    @include breakpointup(large) {
      height: $topbar-height;
    }

    @include breakpointdown(large) {
      height: $topbar-height-mobile;

      .top-bar-left {
        img {
          max-width: 156px;
        }
      }
    }

    @include breakpointdown(mobile) {
      .top-bar-left {
        img {
          max-width: 100px;
        }
      }
    }

    .top-bar-right {
      @include flex;
      @include flex-align(null, middle);
    }

    .top-bar-right {
      .campus-search {
        @include breakpointup(xlarge) {
          margin-left: 0.5rem;
        }

        @include breakpointdown(xlarge) {
          .search-button {
            &__mobile {
              display: block;
            }
          }

          &:not(.opened) {
            .campus-searchinput-wrapper {
              display: none;
            }
          }

          .campus-searchinput-wrapper {
            position: fixed;
            left: 0px;
            right: 0px;
            z-index: 1100;
            background-color: $white;
            @include padding(0.75, 0.75, 0.75, 0.75);
            @include margin(1.25);

            input[type='search'] {
              max-width: 100%;
            }
          }
        }

        input[type='search'] {
          margin: 0px;
          margin-right: 0px;
          min-width: 16.5rem;
        }
      }
    }
  }

  @include breakpointdown(medium) {
    .campus-container {
      padding-right: 0px;
    }
  }
}

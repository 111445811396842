@import '../../styles/settings';
@import 'foundation-sites/scss/foundation';

.campus-search {
  .campus-searchinput-wrapper {
    @include flex;
    @include flex-align(null, middle);
  }

  .search-button {
    border-radius: $global-radius;
    margin-bottom: 0px;
    border-color: $black;

    &__mobile {
      display: none;
      position: relative;
      padding: 0.25rem 1rem;
      cursor: $global-button-cursor;
      border-left: 1px solid $menu-border-color;

      img {
        height: 30px;
        width: 30px;
      }
    }
  }

  input[type='search'] {
    height: auto;
    margin: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

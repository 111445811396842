@import 'settings';
@import 'foundation-sites/scss/foundation';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
// @include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;

// Generic components
@include foundation-button;
// @include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;

// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
@include foundation-accordion;
// @include foundation-media-object;
// @include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;

// Menu-based containers
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
// @include foundation-sticky;
// @include foundation-title-bar;
// @include foundation-top-bar;

// Helpers
// @include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

.campus-container {
  width: 100%;
  margin: 0px auto;

  .button {
    text-decoration: none;
  }

  @include breakpointup(xxlarge) {
    max-width: 1440px;
    padding-left: 10rem;
    padding-right: 6.5rem;
  }

  @include breakpointbetween(xlarge, xxlarge) {
    max-width: 1200px;
    padding-left: 1.75rem;
    padding-right: 2.5rem;
  }

  @include breakpointdown(xlarge) {
    padding-left: 1.75rem;
    padding-right: 2.5rem;
  }

  @include breakpointdown(medium) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.campus-title {
  color: get-color(primary);
  line-height: 120%;
  margin-bottom: 1rem;

  @include breakpointup(xlarge) {
    font-size: 2.875rem;
  }

  @include breakpointbetween(large, xlarge) {
    font-size: 2.275rem;
  }

  @include breakpointdown(large) {
    font-size: 2rem;
  }
}

.campus-subtitle {
  line-height: 112%;
  font-size: 1.5rem;

  &:not(.no-margin) {
    margin-bottom: 1rem;
  }

  &.primary {
    color: get-color(primary);
  }

  &.small {
    font-size: 1rem;
  }

  @include breakpointup(large) {
    font-size: 1.875rem;

    &.small {
      font-size: 1.875rem;
    }
  }
}

.campus-text {
  color: $black;
  font-size: 1rem;
  line-height: 120%;
  margin-bottom: 1rem;

  &.semi {
    font-size: 1.125rem;
  }

  @include breakpointup(large) {
    font-size: 1.125rem;

    &.semi {
      font-size: 1.5rem;
    }
  }
}

.campus-section-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: get-color(primary);
}

.font-semi {
  font-weight: 700;
}

.font-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.campus-strand-title {
  margin-bottom: 1.375rem;


  h1,
  h2 {
    font-size: 2.25rem;
    line-height: 120%;
    margin-bottom: 0px;
    line-height: 120%;
  }

  h1 {
    font-weight: 700;
    color: $strands-title-color;
  }

  h2 {
    font-weight: 600;
  }
}
